import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Languages from '../Languages';
import SrcLogoMini from './images/legal-logo.png';
import {
  HeaderWrapper,
  NavBar,
  NavBarLeftItems,
  NavBarRightItems,
  LogoMini,
  ProfileLink,
  ClientInfo,
  MButtonCopy,
} from './index.styles';
import config from '../../config';
import useIam from '../../hooks/useIam';

export default function Header() {
  const { clientId } = useParams<{clientId:string}>();
  const [clientInfo, setClientInfo] = useState({ first_name: '', last_name: '' });
  const { isManager } = useIam();
  // get client's full name for page title
  useEffect(() => {
    if (clientId) {
      axios
        .get(`${config.api.url}clients/${clientId}/info`)
        .then((res) => {
          setClientInfo(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [clientId]);
  return (
    <>
      <Helmet>
        <title>
          {clientInfo.first_name && clientInfo.last_name ? `${clientInfo.first_name} ${clientInfo.last_name} | ` : ''}
          Legal Indonesia Group, LegalIndonesia.id
        </title>
      </Helmet>
      <HeaderWrapper>
        <NavBar>
          <NavBarLeftItems>
            <Link to={`/${clientId}`}>
              <LogoMini src={SrcLogoMini} alt="Logo of Legal Indonesia" />
            </Link>
          </NavBarLeftItems>
          <NavBarRightItems>
            <Languages />
            <ProfileLink to={`/${clientId}/profile`}>
              <FontAwesomeIcon icon={faUserCircle} />
              <ClientInfo>
                {clientInfo.first_name}
                <br />
                {clientInfo.last_name}
              </ClientInfo>
            </ProfileLink>
            {isManager
            && (
              <CopyToClipboard text={`${clientInfo.first_name} ${clientInfo.last_name}`}>
                <MButtonCopy>Copy</MButtonCopy>
              </CopyToClipboard>
            )}
          </NavBarRightItems>
        </NavBar>
      </HeaderWrapper>
    </>
  );
}
