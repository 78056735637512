import React from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useTranslation} from 'react-i18next';
import config from '../config';
import ThumbnailViewer from '../components/Cabinet/ThumbnailViewer';
import {getUrlFromField} from '../components/Cabinet/helper';

/* API has the same field list */
export interface IExtensions {
    id: string,
    fields: {
        '211Contract': string,
        EntryVisaType: string,
        Paid: string,
        PaidTo: string,
        ClientRecordID: string,
        'Client (from 211Contract)': string,
        'Email (from Client) (from 211Contract)': string,
        Type: string,
        Total: number,
        'QR-photo': string,
        QrPhotoLink: string,
        'Imm office': string,
        Status: string,
        LastDay: any,
        EffLastDay: any,
        ExtPhoto: string,
        ExtPhotoLink: string,
        Agent: string,
        PIC: string,
        'Created (from 211Contract)': string,
        'BioComplete': string,
        BioTalonLink: string,
        EntryStampLink: string,
        VisaExpireAt: string,
        eVoaVisaFileLink: string,
        PersonPhotoLink: string|undefined,
    }
}

export function NotifyText({imageUrl}: { imageUrl: string }) {
    const {t} = useTranslation();
    return (
        <div key="notify_invitation_to_biometric">
            {t('notify_invitation_to_biometric')}
            {' '}
            <ThumbnailViewer img={imageUrl} asLink linkText={t('notify_invitation_to_biometric_link') as string}/>
        </div>
    );
}

const useExtensions = (clientId: string = '', freshData: boolean = false) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<Array<IExtensions>>([]);
    const [notify, setNotify] = useState<any>();
    const getExtensions = useCallback(() => {
        if (clientId) {
            axios
                .get(`${config.api.url}clients/${clientId}/extensions`)
                .then((res) => {
                    setData(res.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [clientId]);
    const fresh = () => {
        // setIsLoading(true);
        getExtensions();
    };
    const canCreateNextExtensionFor211Visa = () => {
        // if the client's last extension of 211 visa is not VOA, Type is 211A-1 and extension is Paid than
        // he can create next extension
        if (data.length) {
            const lastExt = data[data.length-1];
            const Type = lastExt.fields.Type;
            const isPaid = !!lastExt.fields.Paid;
            if (Type === '211A-1' && isPaid) {
                return true;
            }
        }
        return false;
    };
    const getNextExtensionFor211VisaParams = () => {
        if (data.length) {
            const lastExt = data[data.length-1];
            const fields = lastExt.fields;
            const entryVisaId = fields['211Contract'][0];
            return {
                entryVisaId,
                type: `211A-2`,
                immOffice: fields['Imm office'],
                agent: fields.Agent,
                pic: fields.PIC,
            }
        }
        return {};
    };

    const canCreateVOAExtension = () => {
        // Client can create VOA extension if any of the following conditions are true:
        // 1. client hasn't extensions
        if (!data.length) {
            return true;
        }
        if (data.length) {
            const lastExt = data[data.length-1];
            const Type = lastExt.fields.Type;
            const isPaid = !!lastExt.fields.Paid;
            const Status = lastExt.fields.Status;
            // 2. client's last extension is 211A-2, and it's Paid
            if (Type === '211A-2' && isPaid) {
                return true;
            }
            // 3. client's last extension is VOA, and it's Paid, and status is any of Done, Returned
            if (Type === 'VOA' && ['Done', 'Returned'].includes(Status)) {
                return true;
            }
        }
        return false;
    };

    const canDownloadDocLackOfPassport = () => {
        return data.some(item => item.fields.Status === 'In immigration');
    };

    useEffect(() => {
        if (!data.length || freshData) {
            setIsLoading(true);
            getExtensions();
        }
    }, [data.length, freshData, getExtensions]);
    useEffect(() => {
        if (data.length) {
            // eslint-disable-next-line array-callback-return
            data.map((record) => {
                const {fields} = record;
                if (fields['QR-photo'] && fields['Imm office'] && fields.BioComplete === undefined) {
                    const text = (
                        <NotifyText imageUrl={getUrlFromField(fields['QR-photo'])}/>
                    );
                    setNotify({text, type: 'warning'});
                }
            });
        }
    }, [data]);
    return {
        isLoading,
        data,
        notify,
        fresh,
        canCreateNextExtensionFor211Visa,
        getNextExtensionFor211VisaParams,
        canCreateVOAExtension,
        canDownloadDocLackOfPassport,
    };
};

export default useExtensions;
